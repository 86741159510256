import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = (props) => {
  const [muted, setMuted] = useState(true);
  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    // Verify if the video title contains "[Vertical]" when the component is mounted
    if (props.title && props.title.includes("[Vertical]")) {
      setIsVertical(true);
    }
  }, [props.title]); // Dependencies in the array for only execute when the slied change

  // Change state of mute
  const toggleMuted = () => {
    setMuted(!muted);
  };

  return (
    <div style={{
      display: isVertical ? 'flex' : 'block', 
      justifyContent: 'center', 
      alignItems: 'center',
      flexDirection: 'column', 
      backgroundColor: '#353535',
    }}>
      <ReactPlayer
        muted={muted}
        url={props.slideUrl}
        className='react-player'
        width={props.width}
        playing
        loop
        height={isVertical ? '100vh' : 'auto'} 
        />

      <input id='muted' type='checkbox' checked={muted} onChange={toggleMuted} />
      <label className="mute-button" htmlFor="muted">Sound</label>
    </div>
  );
};

export default VideoPlayer;
