import React, { Component } from "react";
import VideoPlayer from './video-player'
import VimeoPlayer from './vimeo-player'
import '../layouts/flickity.scss'
import './slider.scss';
import Img from "gatsby-image";

class Slider extends Component {
  constructor() {
    super();
    this.state = {
      cursor: '',
      scrollPosition: 0,
      scrollDirection: 'down',
      window: null,
      windowHeight: 0,
      vimeoHeight: 0,
      vimeoWidth: 0,
      vimeoHeightProject: 0,
      vimeoWidthProject: 0
    };
  }

  componentDidMount() {
    var Flickity = require('flickity-imagesloaded');
    window.addEventListener("orientationchange", this.handleResize);
    window.addEventListener('scroll', this.shiftCarousel);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();


    if (window) {
      this.updateWindow(window);
    }

    var video = document.getElementsByTagName("video")[0];
    if (video) video.addEventListener("loadeddata", this.resizeCarousel);

    if (Flickity) {
      this.flkty = new Flickity('.carousel', {
        freeScroll: false,
        wrapAround: false,
        pageDots: false,
        imagesLoaded: true,
        prevNextButtons: true,
        autoPlay: false,
        contain: true,
        freeScrollFriction: 0.03,
        cellAlign: this.props.data.length > 1 && this.props.isProject ? 'left' : 'center',
        groupCells: '70%'
      });

      const carousel = document.querySelector('.carousel');

      const flkty = this.flkty;

      if (flkty && !this.props.private) {
        flkty.focus();
        const viewport = document.getElementsByClassName('flickity-viewport')[0];
        if (this.props.data.length < 2) {
          viewport.className += ' single';
        }

        // flkty.on('staticClick', ( event, pointer, cellElement, cellIndex ) => {
        //   if (!cellElement) {
        //     if (event.x < carousel.clientWidth / 2) {
        //       flkty.previous(true, false)
        //     } else {
        //       flkty.next();
        //     }
        //   }
        // });

        setTimeout(function() {
          if (carousel) {
            flkty.resize();
            carousel.classList.remove('is-hidden');
          }
        }, 1000)
      }

      if (this.props.private) {
        flkty.destroy();
        carousel.classList.remove('is-hidden');
      }
    }
  }

  resizeCarousel = () => {
    if (window.innerWidth > 768) {
      this.flkty.resize();
    }
  }

  updateWindow = (win) => {

    let cellHeight;
    let projectHeight = win.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    let carouselElement = document.getElementById("carousel-container");
    let newsHeight;
    if (carouselElement) newsHeight = carouselElement.clientHeight

    if (this.props.isProject) cellHeight = projectHeight;
    if (win.innerWidth <= 768 && this.props.isProject) cellHeight = projectHeight * .86;
    if (win.innerWidth <= 768 && this.props.isNews) cellHeight = newsHeight;
    if (win.innerWidth > 768 && this.props.isNews) cellHeight = newsHeight;

    this.setState({
      window: window,
      windowHeight: cellHeight
    })
  }

  toggleVideo = (toggle) => {
    const v = document.querySelector('video');

    this.flkty.on('staticClick', function( event, pointer, cellElement, cellIndex ) {
      v.addEventListener('play', function() {
        v.play();
      }, false);

      v.onclick = function() {
        if (v.paused) {
          v.play();
        } else {
          v.pause();
        }

        return false;
      };
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.shiftCarousel);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    let windowObject = window;
    if (this.state.window) windowObject = this.state.window;

    let carousel = document.getElementById('carousel');

    let wp = '100%';
    if (carousel) wp = carousel.innerWidth;
    let vimHeightCalc = wp/1.77727;
    let hp = `${vimHeightCalc}px`;


    let h = '100%';
    if (carousel) h = carousel.innerHeight;
    let vimWidthCalc = h*1.77727;
    let w = `${vimWidthCalc}px`;

    let cellHeight;
    let projectHeight = windowObject.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    let carouselElement = document.getElementById("carousel-container");
    let newsHeight;
    if (carouselElement) newsHeight = carouselElement.clientHeight

    if (this.props.isProject) cellHeight = projectHeight;
    if (windowObject.innerWidth <= 768 && this.props.isProject) cellHeight = projectHeight * .86;
    if (windowObject.innerWidth <= 768 && this.props.isNews) cellHeight = newsHeight;
    if (windowObject.innerWidth > 768 && this.props.isNews) cellHeight = newsHeight;

    this.setState({
      windowHeight: cellHeight,
      vimeoHeight: h,
      vimeoWidth: w,
      vimeoHeightProject: hp,
      vimeoWidthProject: wp
    })
  }

  shiftCarousel = () => {
    const scrollTop = document.body.getBoundingClientRect().top;

    if (scrollTop > this.state.scrollPosition) {
      this.setState({ scrollDirection: 'up' })
    } else {
      this.setState({ scrollDirection: 'down'})
    }

    this.setState({
      scrollPosition: scrollTop
    })

    if (this.flkty && !this.props.isProject) {
      let velo = -0.25;
      let directionValue = -0.1;

      if (this.state.scrollDirection === 'up') {
        velo = 0.35;
        directionValue = 0.15;
      }

      this.flkty.velocity = velo;
      const current = this.flkty.x;
      const moveValue = current + 10 * directionValue;

      this.flkty.x = moveValue;
      this.flkty.dragEnd();
    }
  }

  checkLoad = () => {
    console.log('load');
  }

  render() {
    const {data, isProject, isNews} = this.props;
    const {cursor} = this.state;
    let windowHeight = this.state.windowHeight !== 0 ? Math.floor(this.state.windowHeight) : "100%";
    const single = data && data.length === 1;

    return (
      <div id="carousel" className={`carousel is-hidden ${cursor}`}>
        {data &&
          data.map((slide, i) => {
            let widthCalc = windowHeight * slide.fluid.aspectRatio;
            let width = `auto`;
            if (windowHeight) width = `${widthCalc}px`;

            const thumbnail = slide.thumbnail ? true : false;
            const urlInDescription = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
            const isVimeoAsset = slide.description && slide.description.search(urlInDescription) > -1 && slide.file.contentType !== "video/mp4";
            const isCfVideoAsset = slide.file.contentType === "video/mp4" && !isVimeoAsset;
            let singleVideo = 'multi-video';
            if ((single && isVimeoAsset) || (single && isCfVideoAsset)) {
              singleVideo = 'is-single-video'
            }

            if (thumbnail) {
              return (
                <a key={i} className="carousel-cell">
                  <img src={slide.thumbnail.file.url} />
                </a>
              )
            } else {
              if (isVimeoAsset) {
                return (
                  <div key={i} onClick={() => this.toggleVideo('toggle')} className={`carousel-cell video ${singleVideo}`}>
                    <VimeoPlayer
                      h={this.state.vimeoHeight}
                      w={this.state.vimeoWidth}
                      hp={this.state.vimeoHeightProject}
                      wp={this.state.vimeoWidthProject}
                      isProject={isProject}
                      hasControls
                      slideUrl={slide.description}
                      width={isProject ? "auto" : "auto"} />
                  </div>
                )
              }
              if (isCfVideoAsset) {
                return (
                  <div key={i} onClick={() => this.toggleVideo('toggle')} className={`carousel-cell video ${singleVideo}`}>
                    <VideoPlayer
                      title={slide.title}
                      hasControls
                      slideUrl={slide.file.url}
                      width={isProject ? "auto" : "auto"} />
                  </div>
                )
              } else {
                if (this.props.private) {
                  if (isVimeoAsset) {
                    return (
                      <div key={i} onClick={() => this.toggleVideo('toggle')} className={`carousel-cell video ${singleVideo}`}>
                        <VimeoPlayer
                          h={this.state.vimeoHeight}
                          w={this.state.vimeoWidth}
                          hp={this.state.vimeoHeightProject}
                          wp={this.state.vimeoWidthProject}
                          isProject={isProject}
                          hasControls
                          slideUrl={slide.description}
                          width={isProject ? "auto" : "auto"} />
                      </div>
                    )
                  }
                  if (slide.file.contentType === "video/mp4" && !isVimeoAsset) {
                    return (
                      <div key={i} onClick={() => this.toggleVideo('toggle')} className={`carousel-cell video ${singleVideo}`}>
                        <VideoPlayer
                          hasControls
                          slideUrl={slide.file.url}
                          width={isProject ? "auto" : "auto"} />
                      </div>
                    )
                  } else {
                    return (
                      <div key={i} style={{"width": width}} className="carousel-cell" >
                        <Img fluid={slide.fluid} loading="eager" />
                      </div>
                    )
                  }
                } else {
                  return (
                    <div key={i} style={{"width": width}} className="carousel-cell" >
                      <Img fluid={slide.fluid} />
                    </div>
                  )
                }
              }
            }
          })
        }
      </div>
    );
  }
}

export default Slider;
