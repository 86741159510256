import React, { Component } from "react";
import ReactPlayer from 'react-player'

class VimeoPlayer extends Component {
  constructor() {
    super();
    this.state = {
      muted: true
    };
  }

  toggleMuted = () => {
    this.setState({ muted: !this.state.muted })
  }

  render() {

    return (
      <div>
        <ReactPlayer
          url={this.props.slideUrl}
          className='react-player vimeo'
          controls={false}
          height={this.props.isProject ? this.props.hp : this.props.h}
          width={this.props.isProject ? this.props.wp : this.props.w}
          config={{ vimeo: { playerOptions: { background: true }}}}
          volume={1}
          muted={this.state.muted}
          playing
          loop
          playsinline />
        <input id='vim-muted' type='checkbox' checked={this.state.muted} onChange={this.toggleMuted} />
        <label className="mute-button" htmlFor="vim-muted">Sound</label>
      </div>
    );
  }
}

export default VimeoPlayer;
