import React, { Component } from "react";
import { navigate } from "gatsby"
import Slider from '../components/slider'
import Helmet from 'react-helmet'
import BodyClassName from 'react-body-classname';
import './project.scss'
import { graphql } from "gatsby"

class ProjectTemplate extends Component {
  constructor() {
    super();
    this.state = {
      info: false,
      dark: 'light'
    };
  }

  darken = (hasDescription) => {
    if (this.props.data.contentfulProject.description) {
      this.setState({
        dark: 'dark'
      })
    }

    if (!this.props.data.contentfulProject.description && hasDescription !== false) {
      this.setState({
        dark: 'dark'
      })
    }
  }

  lighten = () => {
    this.setState({
      dark: 'light'
    })
  }

  showInfo = () => {
    const header = document.getElementById("header");
    const footer = document.getElementById("footer");
    const footerDots = document.getElementById("footer-dots");
    const projInfo = document.getElementById("project-info");
    const expandedSection = document.getElementById("carousel-container");

    if (this.props.data.contentfulProject.description) {
      this.setState({
        info: !this.state.info
      })

      if (!this.state.info) {
        projInfo.style.transform = `translate3d(0, -50%, 0)`
        projInfo.style.opacity = `1`;
        projInfo.style.zIndex = `9999`;
        footer.style.height = `70%`;
        footerDots.style.opacity = `0`;
        footerDots.style.marginTop = `100%`;
      } else {
        projInfo.style.transform = `translate3d(0, 0, 0)`
        projInfo.style.opacity = `1`;
        projInfo.style.zIndex = `-10`;
        footer.style.height = `auto`;
        footerDots.style.opacity = `1`;
        footerDots.style.marginTop = `0%`;
      }
    }
  }

  render() {
    const project = this.props.data.contentfulProject;
    const {dark} = this.state;
    const hasDescription = project.description ? true : false;
    const clickable = hasDescription ? 'clickable' : '';
    const artistName = project.artist ?  project.artist.fullName : '';
    let prevPath = project.artist.slug;

    if (this.props.location.state) prevPath = this.props.location.state.prevPath;
    return (
      <BodyClassName className="project-page">
        <div className="project-wrapper">
          <Helmet>
            <meta property="og:title" content={`${artistName} | ${project.title}`} />
            <title>{artistName} | {project.title}</title>
          </Helmet>

          <div id="header"
            className={`header-name`}
            onClick={ () => navigate(`/${prevPath}`)}
            onMouseOver={() => this.darken()}
            onMouseLeave={() => this.lighten()}>

            <a className="back">Back</a>
          </div>

          <div id="carousel-container" className={`carousel-container ${dark}`}>
            {project.carouselImagesAndVideos &&
              <Slider data={project.carouselImagesAndVideos} isProject />
            }

            <div
              onClick={() => this.showInfo()}
              onMouseOver={() => this.darken(hasDescription)}
              onMouseLeave={() => this.lighten()}
              id="footer"
              className={`footer ${clickable}`}>

              <h3 id="footer-dots">...</h3>
            </div>
          </div>

          <div id="project-info" className="project-info">
            {project.description &&
              <div
                className="info"
                dangerouslySetInnerHTML={{ __html: project.description.childMarkdownRemark.html }}
                />
            }
          </div>
        </div>
      </BodyClassName>
    );
  }
}

export default ProjectTemplate;

export const pageQuery = graphql`
  query projectQuery($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      title
      artist {
        ... on ContentfulArtistProfile {
          slug
          fullName
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      carouselImagesAndVideos {
        ... on ContentfulAsset {
          title
          description
          file {
            url
            contentType
          }
        }
        fluid(sizes: "(orientation: portrait) and (min-width: 769px) 210vw, (orientation: portrait) and (max-width: 768px) 250vw") {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
